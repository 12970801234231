import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

export const useAccountSetupForm = ({ ruleset }) => {
  const {
    email,
    firstName,
    lastName,
    policyNumber,
    licenseNumber,
    phone,
    ssnLast4,
    einLast4,
    zip,
  } = ruleset;

  const schema = joi.object({
    ...(email && {
      email: joi.string().email({ tlds: { allow: false } }).required().messages({
        'string.email': 'Email must be a valid email',
        'any.required': 'Email is required',
      }),
    }),
    ...(firstName && { firstName: joi.string().messages({ 'any.required': 'First name is required' }), }),
    ...(lastName && { lastName: joi.string().messages({ 'any.required': 'Last name is required' }), }),
    ...(policyNumber && { policyNumber: joi.string().pattern(/^[A-Za-z0-9-]+$/), }),
    ...(licenseNumber && { licenseNumber: joi.string().alphanum().messages({ 'any.required': 'License number is required' }), }),
    ...(phone && {
      phone: joi.string().pattern(/^\(\d{3}\) \d{3}-\d{4}$/).messages({
        'string.pattern.base': 'Phone number must be a valid US phone number without country code',
        'any.required': 'Phone number is required',
      }),
    }),
    ...(ssnLast4 && {
      ssnLast4: joi.string().length(4).pattern(/^\d{4}$/).required().messages({
        'string.length': 'SSN must be the last 4 digits',
        'string.pattern.base': 'SSN must contain only numbers',
        'any.required': 'SSN is required',
      }),
    }),
    ...(einLast4 && {
      einLast4: joi.string().length(4).pattern(/^\d{4}$/).required().messages({
        'string.length': 'EIN must be the last 4 digits',
        'string.pattern.base': 'EIN must contain only numbers',
        'any.required': 'EIN is required',
      }),
    }),
    ...(zip && {
      zip: joi.string().pattern(/^\d{5}$/).messages({
        'string.length': 'ZIP code must be 5 digits',
        'string.pattern.base': 'ZIP code must contain only numbers',
        'any.required': 'ZIP code is required',
      }),
    }),
  });

  const form = useForm({
    resolver: joiResolver(schema),
  });

  return form;
};