import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { client } from "../../api";

export const usePaymentQuery = () => {
  const [params] = useSearchParams();

  const [ruleset, setRuleset] = useState({});
  const [payment, setPayment] = useState(null);
  const [paymentId, setPaymentId] = useState(null);

  useEffect(() => {
    let canceled = false;
    const start = dayjs().unix();

    const paymentId = params.get('paymentId');
    const paperPreferred = params.get('paperPreferred');

    setPaymentId(paymentId);

    const f = async () => {
      if (paperPreferred)
        await client({
          method: 'patch',
          url: `/api/pay/public/paperPreferred/${paymentId}`
        });

      const { data: { ruleset, ...rest } } = await client({
        method: 'get',
        url: `/api/pay/verify/${paymentId}`
      });

      if (canceled) return;

      const end = dayjs().unix();

      const additionalDelay = 1000 - (end - start);

      setTimeout(() => {
        setRuleset(ruleset);
        setPayment(rest)
      }, additionalDelay >= 0 ? additionalDelay : 0);
    };

    f();

    return () => canceled = true;
  }, [params]);

  return {
    ruleset,
    payment,
    paymentId,
  };
}