import succuessIcon from '../../../../../assets/circle-check.svg'

export const Pending = () => {
  return <div className='flex flex-col w-full p-10 justify-center items-center space-y-5'>
    <img alt='bank' src={succuessIcon} className='w-20' />
    <div className='space-y-3'>
      <div className='text-lg text-center text-slate-gray font-bold leading-6'>
        Account Setup Pending
      </div>
      <div className='text-sm text-center text-slate-gray'>
        We've received all of your information and someone from our team will be reaching out to you shortly!
      </div>
    </div>
  </div>;
}