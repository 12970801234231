import { useEffect, useState } from 'react';
import { client } from '../../../../api';
import { useLink } from '../../../../hooks/useLink';
import { AxiosError } from 'axios';
import { Info } from './Cards/Info';
import { useToasts } from '../../../../contexts/useToasts';
import { Card } from '../../../../components/Card';
import { Verify } from './Cards/Verify';
import { Button } from '../../../../components/Button';
import { InfoTitle } from './Titles/Info';
import { VerifyTitle } from './Titles/Verify';
import { Link } from './Cards/Link';
import { Loading } from '../../../../components/Loading';
import { Success } from './Cards/Success';
import { Pending } from './Cards/Pending';
import { usePaymentQuery } from '../../../../hooks/usePaymentQuery';
import { useAccountSetupForm } from '../../../../hooks/useAccountSetupForm';

export const AccountSetup = () => {
  const { ruleset, payment, paymentId } = usePaymentQuery();
  const form = useAccountSetupForm({ ruleset });
  const { dispatch } = useToasts();

  const [memberId, setMemberId] = useState(null);

  const { open, ready, success } = useLink({ memberId });

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);


  const { handleSubmit } = form;

  useEffect(() => {
    if (!success)
      return;

    success === 'verified' ? setStep(3) : setStep(4);
    setLoading(false);
  }, [success]);

  const onSubmit = async data => {
    setStep(s => s + 1);

    try {
      if (!paymentId) return;

      const { phone, ...rest } = data;

      const { data: { memberId } } = await client({
        method: 'post',
        url: `/api/pay/verify`,
        data: {
          paymentId,
          phone: phone.replace(/\D/g, ''),
          ...rest,
        }
      });

      setMemberId(memberId);
    }
    catch (error) {
      if (error instanceof AxiosError)
        dispatch({
          type: 'ADD_TOAST',
          value: {
            title: 'Something went wrong!',
            body: error.response?.data?.message?.toString() || '',
          }
        });

      setStep(s => s - 1);
    }
  };

  const onNext = async () => {
    if (step === 1) {
      setLoading(true);
      await handleSubmit(onSubmit)();
      setLoading(false);
    }

    if (step !== 1)
      setStep(s => s + 1);
  };

  return <>
    <div className='flex flex-col justify-center items-center space-y-6 p-3'>
      {[
        <InfoTitle />,
        <VerifyTitle />,
      ][step]}
      <Card>
        {[
          <Info {...{ payment }} />,
          <Verify {...{ ...form, ruleset }} />,
          <Link {...{
            onClick: () => {
              setLoading(true);
              open();
            }, ready
          }} />,
          <Success />,
          <Pending />
        ][step]}
        {loading && <Loading />}
      </Card>
      <Button
        hidden={!(payment?.status === 'unclaimed' && step < 2)}
        label='Continue'
        onClick={onNext}
      // disabled={!payment || (step === 1
      //   && !form.formState.isValid
      // )}
      />
    </div>
  </>;
}


