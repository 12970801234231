import Tooltip from "../../../../../components/ToolTip";
import useFormattedNumber from "../../../../../hooks/useFormattedNumber";

export const Verify = ({ getValues, register, formState: { errors, touchedFields }, ruleset }) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    licenseNumber,
    ssnLast4,
    einLast4,
    zip,
    policyNumber,
  } = ruleset;

  const [formattedPhoneNumber, setFormattedNumber] = useFormattedNumber();

  return <div className='flex flex-col space-y-3 p-10 pt-10'>
    {firstName && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        First Name
        <Tooltip>
          Your first name as it appears on the insurance policy.
        </Tooltip>
      </span>
      <input name='firstName' id='firstName' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.firstName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('firstName') || ''} {...register('firstName', { required: true })} />
      {touchedFields.firstName && errors.firstName && <p className='mt-1 text-xs text-red-400'>{errors.firstName.message}</p>}
    </label>}
    {lastName && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">Last Name
        <Tooltip>
          Your last name as it appears on the insurance policy.
        </Tooltip>
      </span>
      <input name='lastName' id='lastName' type="text" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.lastName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('lastName') || ''} {...register('lastName', { required: true })} />
      {touchedFields.lastName && errors.lastName && <p className='mt-1 text-xs text-red-400'>{errors.lastName.message}</p>}
    </label>}
    {email && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Email
        <Tooltip>
          The email address associated with the insurance policy.
        </Tooltip>
      </span>
      <input name='email' id='email' type="email" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.email ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('email') || ''} {...register('email', { required: true })} />
      {touchedFields.email && errors.email && <p className='mt-1 text-xs text-red-400'>{errors.email.message}</p>}
    </label>}
    {phone && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Phone
        <Tooltip>
          The phone number associated with the insurance policy.
        </Tooltip>
      </span>
      <input name='phone' id='phone' type="phone" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.phone ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('phone') || ''} {...register('phone', { required: true })}
        value={formattedPhoneNumber}
        onChange={e => {
          setFormattedNumber(e.target.value);
        }}
      />
      {touchedFields.phone && errors.phone && <p className='mt-1 text-xs text-red-400'>{errors.phone.message}</p>}
    </label>}
    {policyNumber && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Policy Number
        <Tooltip>
          The policy number associated with the insurance policy.
        </Tooltip>
      </span>
      <input name='policyNumber' id='policyNumber' type="string" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.policyNumber ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('policyNumber') || ''} {...register('policyNumber', { required: true })} />
      {touchedFields.policyNumber && errors.policyNumber && <p className='mt-1 text-xs text-red-400'>{errors.policyNumber.message}</p>}
    </label>}
    {licenseNumber && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        License Number
        <Tooltip>
          Your Driver's License Number.
        </Tooltip>
      </span>
      <input name='licenseNumber' id='licenseNumber' type="string" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.licenseNumber ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('licenseNumber') || ''} {...register('licenseNumber', { required: true })} />
      {touchedFields.licenseNumber && errors.licenseNumber && <p className='mt-1 text-xs text-red-400'>{errors.licenseNumber.message}</p>}
    </label>}
    {ssnLast4 && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Last Four Digits of SSN
        <Tooltip direction='top'>
          The last four digits of your Social Security Number.
        </Tooltip>
      </span>
      <input name='ssnLast4' id='ssnLast4' type="string" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.ssnLast4 ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('ssnLast4') || ''} {...register('ssnLast4', { required: true })} />
      {touchedFields.ssnLast4 && errors.ssnLast4 && <p className='mt-1 text-xs text-red-400'>{errors.ssnLast4.message}</p>}
    </label>}
    {einLast4 && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Last Four Digits of EIN
        <Tooltip>
          The last four digits of your Employer Identification Number.
        </Tooltip>
      </span>
      <input name='einLast4' id='einLast4' type="string" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.einLast4 ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('einLast4') || ''} {...register('einLast4', { required: true })} />
      {touchedFields.einLast4 && errors.einLast4 && <p className='mt-1 text-xs text-red-400'>{errors.einLast4.message}</p>}
    </label>}
    {zip && <label>
      <span class="block text-xs font-medium text-slate-gray pb-1">
        Zip
        <Tooltip>
          The zip code associated with the insurance policy.
        </Tooltip>
      </span>
      <input name='zip' id='zip' type="string" className={`peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.zip ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('zip') || ''} {...register('zip', { required: true })} />
      {touchedFields.zip && errors.zip && <p className='mt-1 text-xs text-red-400'>{errors.zip.message}</p>}
    </label>}
  </div>;
};
