import { Button } from '../../../../../components/Button';
import bankIcon from '../../../../../assets/bank-icon.svg';
import bankButtonIcon from '../../../../../assets/bank-button-icon.svg';
import { Breaker } from '../../../../../components/Breaker';

export const Link = ({ onClick, ready }) => <div className='w-full'>
  <div className='p-10 flex flex-col justify-center items-center space-y-6'>
    <img alt='bank' src={bankIcon} className='w-20' />
    <div className='space-y-3'>
      <div className='text-lg text-center text-slate-gray font-bold'>
        Add Bank Account
      </div>
      <div className='text-sm text-center text-slate-gray leading-4'>
        Use the button below to connect your bank. Follow the prompts.
      </div>
    </div>
    <Breaker />
    <Button label='Link Bank' disabled={!ready} loading={!ready} onClick={onClick} type='md-black'>
      <img alt='bank' src={bankButtonIcon} className='w-4' />
    </Button>
  </div>
</div>;