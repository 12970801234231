import React from "react";

const Tooltip = ({ children, direction }) => <div className="relative inline-block mx-1">
  {direction === 'top' ?
    <div
      className="w-[1.5em] h-[1.5em] bg-none border border-[0.2em] border-blue-500 text-xxs font-semibold rounded-full flex text-blue-500 cursor-pointer group"
    >
      <div className='absolute left-[0.635em] -top-[0.22em]'>
        i
      </div>
      <div className="absolute w-36 -top-[6em] -left-[5.5em] px-2 py-2 text-xs leading-4 font-normal text-white bg-blue-500 rounded-md hidden opacity-0 group-hover:opacity-100 transition ease-in-out duration-200 group-hover:block">
        {children || 'Tooltip'}
        <div className="absolute -bottom-[10px] left-[5.5em] w-3 h-3 fill-current text-white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className='fill-blue-500'>
            <polygon points="0,0 12,0 6,6" />
          </svg>
        </div>
      </div>
    </div>
    : <div
      className="w-[1.5em] h-[1.5em] bg-none border border-[0.2em] border-blue-500 text-xxs font-semibold rounded-full flex text-blue-500 cursor-pointer group"
    >
      <div className='absolute left-[0.635em] -top-[0.22em]'>
        i
      </div>
      <div className="absolute w-36 left-[20px] -top-[1.5em] px-2 py-2 text-xs leading-4 font-normal text-white bg-blue-500 rounded-md opacity-0 hidden group-hover:block group-hover:opacity-100 transition ease-in-out delay-1000 duration-200">
        {children || 'Tooltip'}
        <div className="absolute -left-[4px] top-[1.5em] transform -translate-x-1/2 rotate-90 w-3 h-3 fill-current text-white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 6" className='fill-blue-500'>
            <polygon points="0,0 12,0 6,6" />
          </svg>
        </div>
      </div>
    </div>}
</div>;

export default Tooltip;