
import anime from 'animejs';
import { useEffect, useState } from "react";
import { BottomTab } from "../../../../../components/BottomTab";
import { Breaker } from "../../../../../components/Breaker";
import { PlaceHold } from "../../../../../components/PlaceHold";
import { StatusBadge } from "../../../../../components/StatusBadge";
import { amountToDollars } from '../../../../../utils';

export const Info = ({ payment }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    if (payment) {
      let img = new Image();
      img.src = payment.logoUrl;
      img.onload = () => {
        setImageSrc(payment.logoUrl)
        setTimeout(() => setOpacity(1), 100);
      };

      const amountInDollars = amountToDollars(payment.amount);

      const o = { x: amountInDollars * 0.0 };

      anime({
        targets: o,
        x: amountInDollars,
        easing: 'easeOutExpo',
        duration: 300,
        // delay: 250,
        // round: true,
        update: () => setAmount(o.x.toFixed(2)),
      });

      // Clean up function
      return () => {
        img.onload = null;
      };
    }
  }, [payment]);


  return <div className='w-full'>
    <div className='p-10 pb-6 flex flex-col justify-center items-center space-y-3'>
      <div className='flex items-center justify-center h-20'>
        <PlaceHold
          resolved={imageSrc}
          set={[{ w: 80, h: 80 }]}
        >
          <div className='bg-light rounded-full overflow-hidden'>
            <img alt='companyLogo' src={imageSrc} className={`w-20 h-20 transition-opacity duration-500 ease-in opacity-${opacity} object-cover`} />
          </div>
        </PlaceHold>
      </div>
      <div className='flex items-center justify-center text-xl text-center text-primary font-bold h-16'>
        <PlaceHold
          resolved={payment}
          set={[
            { w: 170, h: 22 },
            { w: 130, h: 22 },
          ]}
          direction='col'
          justify={'items-center'}
        >
          {payment?.name || ''}
        </PlaceHold>
      </div>
      <Breaker />
      <div className='flex justify-center items-center pt-1'>
        <div className={`flex items-center text-center text-primary tracking-tight font-black text-5xl space-x-1 transition-opacity duration-[1000ms] ease-out ${!payment ? 'opacity-[0.5]' : 'opacity-1'}`}>
          <div className='text-4xl'>
            $
          </div>
          <div>
            {amount}
          </div>
        </div>
      </div>
    </div>
    <BottomTab>
      <div className='flex space-x-1 w-full'>
        <span className='w-14 text-secondary text-xs underline'>Details</span>
      </div>
      <PlaceHold
        resolved={payment}
        set={[
          { w: 130, h: 15 },
          { w: 160, h: 15 },
          { w: 120, h: 15 },
        ]}
        direction='col'
      >
        <div className='flex space-x-1 w-full'>
          <span className='w-14 text-slate-gray font-bold text-xs'>Claim #</span>
          <span className='text-slate-gray text-xs'>{payment?.claimNumber || 'none'}</span>
        </div>
        <div className='flex space-x-1 w-full'>
          <span className='w-14 text-slate-gray font-bold text-xs'>Created</span>
          <span className='text-slate-gray text-xs'>{payment?.claimDate || 'none'}</span>
        </div>
        <div className='flex space-x-1 w-full'>
          <span className='w-14 text-slate-gray font-bold text-xs'>Status</span>
          <span>
            <StatusBadge>
              {payment?.status || 'none'}
            </StatusBadge>
          </span>
        </div>
      </PlaceHold>
    </BottomTab>
  </div>;
};