import { useState, useEffect, useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";
import { client } from "../../api";
// import { stringify } from 'querystring';

export const useLink = ({ memberId }) => {
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(null);

  const onSuccess = useCallback(async (publicToken, metadata) => {
    const { data: { status } } = await client({
      url: '/api/pay/members/accounts',
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        memberId,
        publicToken,
        accountId: metadata.accounts[0].id
      },
    });

    setSuccess(status);
  }, [memberId, setSuccess]);

  // Creates a Link token
  const createLinkToken = useCallback(async () => {
    // For OAuth, use previously generated Link token
    if (window.location.href.includes("?oauth_state_id=")) {
      const linkToken = localStorage.getItem('link_token');
      setToken(linkToken);
    } else {
      // @TODO: Temp for dev/ Plaid sandbox.
      // const [redirect] = window.location.href.match(/^(http(s)?:\/\/.*\/)/);
      // const qs = stringify({ redirect: 'https://localhost:3000/' });
      const { data } = await client(`/api/plaid/linkToken/${memberId}`)//?${qs}`);
      setToken(data.link_token);
      localStorage.setItem("link_token", data.link_token);
    }
  }, [memberId, setToken]);

  let isOauth = false;

  const config = {
    token,
    onSuccess,
  };

  // For OAuth, configure the received redirect URI
  if (window.location.href.includes("?oauth_state_id=")) {
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (token == null && memberId) {
      createLinkToken();
    }

    if (isOauth && ready) {
      open();
    }
  }, [token, isOauth, ready, open, memberId, createLinkToken]);

  return { open, ready, success };
};