import { Badge } from "../Badge";

export const StatusBadge = ({ children }) => {
  let color;

  if (children === 'pending') {
    color = 'bg-orange-500';
  }
  else if (children === 'completed') {
    color = 'bg-green-500';
  }
  else if (children === 'canceled') {
    color = 'bg-red-500';
  }
  else if (children === 'paper') {
    color = 'bg-yellow-500';
  }
  else {
    color = 'bg-secondary';
  }

  return <Badge className={color}>
    {children}
  </Badge>;
};